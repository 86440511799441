.common_tree_handle[data-v-79447ca9] {
  width: 280px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #ffffff;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.common_tree_handle .el-tree[data-v-79447ca9] {
  /* height:100% !important; */
  height: calc(100vh - 300px) !important;
  overflow-y: scroll;
}
[data-v-79447ca9] .el-tree-node__content {
  line-height: 26px;
}