.addThirduser {
  position: relative;
}
.addThirduser .el-autocomplete {
  width: 100%;
}
.addThirduser .titleSuffix {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.addThirduser .main-box {
  padding-top: 46px;
  height: calc(100% - 46px);
  overflow: scroll;
}
.addThirduser .shuttleBackBox {
  background: #ffffff;
  height: 100%;
}
.addThirduser .el-tabs--border-card > .el-tabs__header {
  border-bottom: 0;
}
.addThirduser .el-tabs__content {
  display: none;
}
.addThirduser .idCodingImg1 .el-upload-list__item {
  width: 226px;
  height: 132px;
}
.addThirduser .idCodingImg1 .el-upload--picture-card {
  height: 132px;
}
.addThirduser .margin-b-10 {
  margin-bottom: 10px;
}
.addThirduser .form-box {
  padding: 12px;
}
.addThirduser .hdStatus {
  margin-left: 10px;
}
.addThirduser .form-item {
  padding: 12px 0 0;
}
.addThirduser .avue-crud__search .el-form-item__label {
  display: none;
}
.addThirduser .avue-crud__search .el-form-item__content {
  margin-left: 0 !important;
}
.addThirduser .avue-crud__search .reset-refresh {
  font-size: 12px;
}
.addThirduser .avue-crud__search .avue-form__menu .el-button {
  margin: 0 !important;
}
.addThirduser .disUoloadSty .el-upload-list__item {
  width: 226px;
  height: 132px;
}
.addThirduser .disUoloadSty .el-upload--picture-card {
  display: none;
}